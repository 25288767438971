import request from '@/util/request'

// 奖金明细列表
export function getQueryBonus(data) {
  return request({
    url: '/member/api/bonus/query-bonus',
    method: 'post',
    data
  })
}
// 奖金明细-实时收益
export function queryCurrentBonus(data) {
  return request({
    url: '/bonus/api/bonus/query-current-bonus',
    method: 'post',
    data
  })
}
// 奖金明细-首购收益
export function queryBonusFirst(data,data1) {
  return request({
    url: '/bonus/api/bonus/query-bonus-first'+data1,
    method: 'post',
    data
  })
}
// 奖金明细-嗨粉收益
export function queryBonusHiFun(data,data1) {
  return request({
    url: '/bonus/api/bonus/query-bonus-hi-fun'+data1,
    method: 'post',
    data
  })
}
// 奖金-计算昨天合计的奖金数量
export function queryBonusTotal(data) {
  return request({
    url: '/bonus/api/bonus/query-bonus-total',
    method: 'post',
    data
  })
}

// 奖金明细-云代收益
export function queryBonusCloud(data,data1) {
  return request({
    url: '/bonus/api/bonus/query-bonus-cloud'+data1,
    method: 'post',
    data
  })
}
// 奖金明细-复购券收益
export function queryBonusCoupon(data,data1) {
  return request({
    url: '/bonus/api/bonus/query-bonus-coupon'+data1,
    method: 'post',
    data
  })
}
// 奖金明细-创客空间收益
export function queryBonusMarker(data,data1) {
  return request({
    url: '/bonus/api/bonus/query-bonus-maker'+data1,
    method: 'post',
    data
  })
}
// 奖金明细-海粉收益
export function queryBonusHaifun(data,data1) {
  return request({
    url: '/bonus/api/bonus/query-bonus-hai-fun'+data1,
    method: 'post',
    data
  })
}
// 奖金明细-复购收益
export function queryBonusRepurchase(data,data1) {
  return request({
    url: '/bonus/api/bonus/query-bonus-repurchase'+data1,
    method: 'post',
    data
  })
}


//奖金来源列表
export function getBonusDetail(data,data1) {
  return request({
    url: '/bonus/api/bonus/query-bonus-detail'+data1,
    method: 'post',
    data
  })
}

//招商列表
export function getBiddingList(params) {
  return request({
    url: '/member/api/market-news/bidding-list',
    method: 'get',
    params
  })
}

//三单循环
export function tripleCycleList(params) {
  return request({
    url: '/member/api/market-news/triple_cycle_list',
    method: 'get',
    params
  })
}

//海粉
export function seaFlourList(params) {
  return request({
    url: '/member/api/market-news/sea_flour_list',
    method: 'get',
    params
  })
}

//注册列表
export function getRegList(params) {
  return request({
    url: '/member/api/market-news/reg-list',
    method: 'get',
    params
  })
}

//收益类型下拉选-奖项配置
export function getBdBonusitemsList(params) {
  return request({
    url: '/system/api/bd-bonus-items/list',
    method: 'get',
    params
  })
}

//注册等级下拉选
export function getGradeList(params) {
  return request({
    url: '/system/api/grade/list',
    method: 'get',
    params
  })
}

//考核明细接口
export function getAssessList(params) {
  return request({
    url: '/member/api/assess/assessList',
    method: 'get',
    params
  })
}


//考核状态复购考核
export function getAssessStatusList(params) {
  return request({
    url: '/system/pub/enums/member-assess-status',
    method: 'get',
    params
  })
}


//考核类型分红考核
export function getchangeTypeList(params) {
  return request({
    url: '/system/pub/enums/change-type',
    method: 'get',
    params
  })
}

//考核分红积分
export function getAssAchieve(params) {
  return request({
    url: '/member/api/assess/assAchieve',
    method: 'get',
    params
  })
}

//活动列表
export function getActivityList(params) {
  return request({
    url: '/activity/api/base/activity-list',
    method: 'get',
    params
  })
}
//三单轮次
export function nextRound(params) {
  return request({
    url: '/member/api/market-news/round-his',
    method: 'get',
    params
  })
}

//活动详情
export function getActivityDetailList(data) {
  return request({
    url: '/activity/api/base/activity-detail',
    method: 'post',
    data
  })
}


//新人礼包详情
export function getGiftPack(data) {
  return request({
    url: '/activity/api/people-service/getGiftPack',
    method: 'post',
    data
  })
}

//抽奖奖品列表
export function getDrawPrizeList(data) {
  return request({
    url: '/activity/api/draw/prize-list',
    method: 'post',
    data
  })
}

//抽奖次数查询
export function getLotteryDrawNum(data) {
  return request({
    url: '/activity/api/draw/lottery-num',
    method: 'post',
    data
  })
}


//抽奖
export function getLotteryDraw(data) {
  return request({
    url: '/activity/api/draw/lottery-draw',
    method: 'post',
    data
  })
}


//在线支付点击在线支付时调用
export function drawOnlinePayDraw(data) {
  return request({
    url: '/activity/api/draw/online-pay-draw',
    method: 'post',
    data
  })
}


//抽奖查询支付明细
export function getLuckdrawDetail(data) {
  return request({
    url: '/activity/api/draw/query-spend-details',
    method: 'post',
    data
  })
}

//月度业绩左右区业绩详情
export function getAchieveDetails(params) {
  return request({
    url: '/member/api/achieve/place-new-add-achieve-details',
    method: 'get',
    params
  })
}
//旅游活动积分
export function tourismPoint(params) {
  return request({
    url: '/activity/api/tourism/tourism-point/' + params,
    method: 'get'
  })
}

//创客空间收益
export function markBonus(data) {
  return request({
    url: '/bonus/api/bonus/query-mark-bonus-detail',
    method: 'post',
    data
  })
}